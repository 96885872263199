import React from 'react';

function Hero() {
  return (
    <div className="relative min-h-screen bg-gradient-to-b from-white to-gray-50 dark:from-dark dark:to-dark/95 pt-16 overflow-hidden">
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-gradient-to-r from-primary/10 to-secondary/10 dark:from-primary/5 dark:to-secondary/5" />
        
        {/* Grid pattern overlay */}
        <div className="absolute inset-0 bg-grid-pattern opacity-[0.02] dark:opacity-[0.05]" />
        
        {/* Animated circles */}
        <div className="absolute inset-0">
          <div className="absolute top-1/4 left-1/4 w-96 h-96 bg-primary/20 dark:bg-primary/10 rounded-full mix-blend-multiply filter blur-xl animate-blob"></div>
          <div className="absolute top-1/3 right-1/4 w-96 h-96 bg-secondary/20 dark:bg-secondary/10 rounded-full mix-blend-multiply filter blur-xl animate-blob animation-delay-2000"></div>
          <div className="absolute bottom-1/4 left-1/2 w-96 h-96 bg-primary/20 dark:bg-primary/10 rounded-full mix-blend-multiply filter blur-xl animate-blob animation-delay-4000"></div>
        </div>

        {/* Floating elements */}
        <div className="absolute inset-0 overflow-hidden">
          <div className="absolute -top-16 -left-16 w-48 h-48 bg-gradient-to-br from-primary/10 to-secondary/10 dark:from-primary/5 dark:to-secondary/5 rotate-45 animate-float"></div>
          <div className="absolute top-1/2 right-0 w-32 h-32 bg-gradient-to-bl from-secondary/10 to-primary/10 dark:from-secondary/5 dark:to-primary/5 rounded-full animate-float animation-delay-2000"></div>
          <div className="absolute bottom-0 left-1/4 w-24 h-24 bg-gradient-to-tr from-primary/10 to-secondary/10 dark:from-primary/5 dark:to-secondary/5 animate-float animation-delay-4000"></div>
        </div>

        {/* Moving particles */}
        <div className="absolute inset-0">
          {[...Array(12)].map((_, i) => (
            <div
              key={i}
              className={`absolute w-2 h-2 bg-gradient-to-r from-primary/30 to-secondary/30 dark:from-primary/20 dark:to-secondary/20 rounded-full
                animate-particle-${i + 1}`}
              style={{
                top: `${Math.random() * 100}%`,
                left: `${Math.random() * 100}%`,
                animationDelay: `${Math.random() * 5}s`
              }}
            />
          ))}
        </div>
      </div>

      <div className="relative min-h-[calc(100vh-4rem)] flex items-center">
        <div className="w-full max-w-[90rem] mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h1 className="text-5xl md:text-6xl lg:text-7xl xl:text-8xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-primary to-secondary animate-fade-in">
              <span className="block leading-tight">Transform Your Vision</span>
              <span className="block leading-tight">Into Digital Reality</span>
            </h1>
            <p className="mt-8 max-w-3xl mx-auto text-lg md:text-xl lg:text-2xl text-gray-600 dark:text-gray-300 animate-slide-up">
              Innovative digital solutions crafted in Sri Lanka & UK. We bring your ideas to life with cutting-edge technology and creative excellence.
            </p>
            <div className="mt-12 flex flex-col sm:flex-row items-center justify-center gap-6">
              <a
                href="#contact"
                className="w-full sm:w-auto px-12 py-5 text-lg font-medium rounded-xl text-white bg-gradient-to-r from-primary to-secondary hover:from-primary/90 hover:to-secondary/90 transform hover:scale-105 transition-all duration-300 shadow-lg hover:shadow-xl animate-slide-up"
                style={{ animationDelay: '0.2s' }}
              >
                Get Started
              </a>
              <a
                href="#services"
                className="w-full sm:w-auto px-12 py-5 text-lg font-medium rounded-xl text-primary dark:text-white border-2 border-primary dark:border-white hover:bg-primary dark:hover:bg-white hover:text-white dark:hover:text-primary transform hover:scale-105 transition-all duration-300 shadow-lg hover:shadow-xl animate-slide-up"
                style={{ animationDelay: '0.4s' }}
              >
                Explore Services
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* Scroll indicator */}
      <div className="absolute bottom-8 left-1/2 transform -translate-x-1/2 animate-bounce">
        <svg className="w-6 h-6 text-primary dark:text-white" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
          <path d="M19 14l-7 7m0 0l-7-7m7 7V3"></path>
        </svg>
      </div>
    </div>
  );
}

export default Hero; 