import React from 'react';

function Projects() {
  const projects = [
    {
      title: "E-Commerce Platform",
      description: "Modern e-commerce solution with advanced features",
      category: "Web Development",
      image: "/images/ecommerce.jpg",
      tags: ["React", "Node.js", "MongoDB"],
      bgColor: "from-blue-500/20 to-indigo-500/20",
      icon: (
        <svg className="w-12 h-12 text-blue-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z" />
        </svg>
      )
    },
    {
      title: "AI Analytics Dashboard",
      description: "Real-time data visualization and predictive analytics platform",
      category: "Data Analytics",
      image: "/images/analytics.jpg",
      tags: ["Python", "TensorFlow", "React"],
      bgColor: "from-purple-500/20 to-pink-500/20",
      icon: (
        <svg className="w-12 h-12 text-purple-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
        </svg>
      )
    },
    {
      title: "Cloud Migration",
      description: "Enterprise-scale cloud infrastructure deployment",
      category: "Cloud Solutions",
      image: "/images/cloud.jpg",
      tags: ["AWS", "Docker", "Kubernetes"],
      bgColor: "from-cyan-500/20 to-blue-500/20",
      icon: (
        <svg className="w-12 h-12 text-cyan-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M3 15a4 4 0 004 4h9a5 5 0 10-.1-9.999 5.002 5.002 0 10-9.78 2.096A4.001 4.001 0 003 15z" />
        </svg>
      )
    },
    {
      title: "Mobile App Suite",
      description: "Cross-platform mobile applications with seamless user experience",
      category: "Mobile Development",
      image: "/images/mobile.jpg",
      tags: ["React Native", "Flutter", "Firebase"],
      bgColor: "from-emerald-500/20 to-teal-500/20",
      icon: (
        <svg className="w-12 h-12 text-emerald-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M12 18h.01M8 21h8a2 2 0 002-2V5a2 2 0 00-2-2H8a2 2 0 00-2 2v14a2 2 0 002 2z" />
        </svg>
      )
    }
  ];

  return (
    <section id="projects" className="py-20 bg-gray-50 dark:bg-dark/50 overflow-hidden">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
        {/* Background Elements */}
        <div className="absolute inset-0 overflow-hidden">
          <div className="absolute right-0 top-0 w-96 h-96 bg-primary/5 dark:bg-primary/10 rounded-full filter blur-3xl"></div>
          <div className="absolute left-0 bottom-0 w-96 h-96 bg-secondary/5 dark:bg-secondary/10 rounded-full filter blur-3xl"></div>
        </div>

        <div className="relative text-center">
          <h2 className="text-4xl md:text-5xl font-extrabold gradient-text">
            Our Projects
          </h2>
          <p className="mt-4 text-xl text-gray-600 dark:text-gray-300 max-w-3xl mx-auto">
            Showcasing our best work and success stories
          </p>
        </div>

        <div className="mt-16 grid gap-8 sm:grid-cols-2 lg:grid-cols-2">
          {projects.map((project, index) => (
            <div
              key={index}
              className="group relative overflow-hidden"
            >
              <div className="modern-card dark:bg-dark/80 dark:border-gray-800 overflow-hidden transform transition-all duration-500 hover:scale-[1.02]">
                {/* Project Header with Icon and Category */}
                <div className={`relative p-6 bg-gradient-to-br ${project.bgColor} dark:bg-opacity-50`}>
                  <div className="flex items-center justify-between">
                    <div className="p-3 bg-white/90 dark:bg-dark/90 rounded-xl shadow-lg">
                      {project.icon}
                    </div>
                    <span className="text-sm font-semibold px-4 py-2 bg-white/90 dark:bg-dark/90 rounded-full shadow-lg">
                      {project.category}
                    </span>
                  </div>
                </div>

                <div className="p-6">
                  <h3 className="text-2xl font-bold text-gray-900 dark:text-white group-hover:gradient-text transition-all duration-300">
                    {project.title}
                  </h3>
                  <p className="mt-3 text-gray-600 dark:text-gray-400">
                    {project.description}
                  </p>
                  
                  {/* Technologies */}
                  <div className="mt-6">
                    <h4 className="text-sm font-semibold text-gray-900 dark:text-white mb-3">Technologies Used</h4>
                    <div className="flex flex-wrap gap-2">
                      {project.tags.map((tag, idx) => (
                        <span 
                          key={idx}
                          className="px-3 py-1 text-sm bg-gradient-to-r from-primary/10 to-secondary/10 dark:from-primary/20 dark:to-secondary/20 text-primary dark:text-white rounded-full border border-primary/20 dark:border-white/20"
                        >
                          {tag}
                        </span>
                      ))}
                    </div>
                  </div>

                  {/* View Project Button */}
                  <div className="mt-6">
                    <button className="w-full px-4 py-3 bg-gradient-to-r from-primary to-secondary text-white rounded-lg opacity-0 group-hover:opacity-100 transform translate-y-2 group-hover:translate-y-0 transition-all duration-300">
                      View Project Details
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Projects; 