import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { seoConfig } from './seo.config';
import reportWebVitals from './reportWebVitals';

// Add meta tags for SEO
const updateMetaTags = () => {
  // Primary Meta Tags
  document.title = seoConfig.title;
  document.querySelector('meta[name="description"]').setAttribute("content", seoConfig.description);
  document.querySelector('meta[name="keywords"]').setAttribute("content", seoConfig.keywords);
  document.querySelector('meta[name="author"]').setAttribute("content", seoConfig.author);

  // Open Graph / Facebook
  document.querySelector('meta[property="og:type"]').setAttribute("content", "website");
  document.querySelector('meta[property="og:url"]').setAttribute("content", seoConfig.siteUrl);
  document.querySelector('meta[property="og:title"]').setAttribute("content", seoConfig.title);
  document.querySelector('meta[property="og:description"]').setAttribute("content", seoConfig.description);
  document.querySelector('meta[property="og:image"]').setAttribute("content", seoConfig.ogImage);

  // Twitter
  document.querySelector('meta[name="twitter:card"]').setAttribute("content", "summary_large_image");
  document.querySelector('meta[name="twitter:site"]').setAttribute("content", seoConfig.twitterHandle);
  document.querySelector('meta[name="twitter:title"]').setAttribute("content", seoConfig.title);
  document.querySelector('meta[name="twitter:description"]').setAttribute("content", seoConfig.description);
  document.querySelector('meta[name="twitter:image"]').setAttribute("content", seoConfig.ogImage);

  // Additional SEO tags
  document.querySelector('meta[name="robots"]').setAttribute("content", "index, follow");
  document.querySelector('meta[name="googlebot"]').setAttribute("content", "index, follow");
  document.querySelector('link[rel="canonical"]').setAttribute("href", seoConfig.siteUrl);
};

// Update meta tags on mount
updateMetaTags();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// Performance monitoring
reportWebVitals();
