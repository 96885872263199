import React from 'react';

function About() {
  return (
    <section id="about" className="py-20 bg-white dark:bg-dark overflow-hidden">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
        {/* Background Elements */}
        <div className="absolute inset-0 overflow-hidden">
          <div className="absolute -right-1/4 -top-1/4 w-96 h-96 bg-primary/5 dark:bg-primary/10 rounded-full filter blur-3xl"></div>
          <div className="absolute -left-1/4 -bottom-1/4 w-96 h-96 bg-secondary/5 dark:bg-secondary/10 rounded-full filter blur-3xl"></div>
        </div>

        <div className="relative lg:grid lg:grid-cols-2 lg:gap-16 items-center">
          <div className="animate-fade-in">
            <h2 className="text-4xl md:text-5xl font-extrabold gradient-text">
              About Olexto Digital Solutions
            </h2>
            <p className="mt-6 text-lg text-gray-600 dark:text-gray-300 leading-relaxed">
              We are a dynamic digital solutions company with offices in Sri Lanka and the UK, 
              bringing together the best of both worlds to deliver exceptional digital experiences.
            </p>
            
            <div className="mt-10 grid grid-cols-2 gap-8">
              <div className="modern-card p-6 dark:bg-dark/80 dark:border-gray-800">
                <p className="text-3xl font-bold gradient-text">5+ Years</p>
                <p className="text-gray-600 dark:text-gray-400 mt-2">Industry Experience</p>
              </div>
              <div className="modern-card p-6 dark:bg-dark/80 dark:border-gray-800">
                <p className="text-3xl font-bold gradient-text">100+</p>
                <p className="text-gray-600 dark:text-gray-400 mt-2">Projects Delivered</p>
              </div>
            </div>
          </div>

          <div className="mt-12 lg:mt-0 animate-slide-up">
            <div className="bg-gradient-to-br from-primary/5 to-secondary/5 dark:from-primary/10 dark:to-secondary/10 rounded-2xl p-8 backdrop-blur-sm">
              <h3 className="text-2xl font-bold text-gray-900 dark:text-white mb-6">Our Global Presence</h3>
              <div className="space-y-8">
                <div className="modern-card p-6 dark:bg-dark/80 dark:border-gray-800">
                  <h4 className="text-xl font-semibold gradient-text">Sri Lanka Office</h4>
                  <p className="text-gray-600 dark:text-gray-300 mt-2">Colombo, Sri Lanka</p>
                  <p className="text-gray-500 dark:text-gray-400 mt-2">Development & Innovation Hub</p>
                </div>
                <div className="modern-card p-6 dark:bg-dark/80 dark:border-gray-800">
                  <h4 className="text-xl font-semibold gradient-text">UK Office</h4>
                  <p className="text-gray-600 dark:text-gray-300 mt-2">London, United Kingdom</p>
                  <p className="text-gray-500 dark:text-gray-400 mt-2">Business & Strategy Center</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About; 