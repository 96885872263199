import React from 'react';

function Clients() {
  const clients = [
    { 
      name: 'Company 1', 
      logo: 'https://via.placeholder.com/150x80/2563eb/FFFFFF?text=Client+1',
      bgColor: "from-blue-500/20 to-indigo-500/20"
    },
    { 
      name: 'Company 2', 
      logo: 'https://via.placeholder.com/150x80/1e40af/FFFFFF?text=Client+2',
      bgColor: "from-indigo-500/20 to-purple-500/20"
    },
    { 
      name: 'Company 3', 
      logo: 'https://via.placeholder.com/150x80/2563eb/FFFFFF?text=Client+3',
      bgColor: "from-purple-500/20 to-pink-500/20"
    },
    { 
      name: 'Company 4', 
      logo: 'https://via.placeholder.com/150x80/1e40af/FFFFFF?text=Client+4',
      bgColor: "from-pink-500/20 to-rose-500/20"
    },
    { 
      name: 'Company 5', 
      logo: 'https://via.placeholder.com/150x80/2563eb/FFFFFF?text=Client+5',
      bgColor: "from-cyan-500/20 to-blue-500/20"
    },
    { 
      name: 'Company 6', 
      logo: 'https://via.placeholder.com/150x80/1e40af/FFFFFF?text=Client+6',
      bgColor: "from-emerald-500/20 to-teal-500/20"
    },
  ];

  return (
    <section className="py-20 bg-white dark:bg-dark/50 overflow-hidden">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
        {/* Background Elements */}
        <div className="absolute inset-0 overflow-hidden">
          <div className="absolute right-0 top-0 w-96 h-96 bg-primary/5 dark:bg-primary/10 rounded-full filter blur-3xl"></div>
          <div className="absolute left-0 bottom-0 w-96 h-96 bg-secondary/5 dark:bg-secondary/10 rounded-full filter blur-3xl"></div>
        </div>

        <div className="relative">
          <div className="text-center">
            <h2 className="text-4xl md:text-5xl font-extrabold gradient-text">
              Trusted by Industry Leaders
            </h2>
            <p className="mt-4 text-xl text-gray-600 dark:text-gray-300 max-w-3xl mx-auto">
              Join our growing list of satisfied clients worldwide
            </p>
          </div>

          {/* Client Logos */}
          <div className="mt-16 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-8">
            {clients.map((client, index) => (
              <div
                key={index}
                className="group relative overflow-hidden"
              >
                <div className="modern-card dark:bg-dark/80 dark:border-gray-800 p-6 flex items-center justify-center transform transition-all duration-500 hover:scale-[1.02]">
                  {/* Background Gradient */}
                  <div className={`absolute inset-0 bg-gradient-to-br ${client.bgColor} dark:opacity-50 opacity-0 group-hover:opacity-100 transition-opacity duration-300`} />
                  
                  {/* Logo */}
                  <div className="relative">
                    <img
                      src={client.logo}
                      alt={client.name}
                      className="max-h-12 w-auto filter dark:brightness-100 brightness-90 group-hover:brightness-100 transition-all duration-300"
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* Call to Action */}
          <div className="mt-16 text-center">
            <a
              href="#contact"
              className="inline-flex items-center px-8 py-4 bg-gradient-to-r from-primary to-secondary text-white rounded-xl hover:from-primary/90 hover:to-secondary/90 transform hover:scale-105 transition-all duration-300 shadow-lg hover:shadow-xl"
            >
              Become Our Partner
              <svg className="w-5 h-5 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 8l4 4m0 0l-4 4m4-4H3"/>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Clients; 